var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "nav-link position-relative text-primary",
      attrs: {
        href: _vm.urlWishList,
        "aria-label": _vm.$translate("Ceres::Template.wishList"),
      },
    },
    [
      _c(
        "b",
        {
          staticClass:
            "position-absolute wishlist-count bg-white text-primary border border-primary rounded-pill px-1",
        },
        [_vm._v(_vm._s(_vm.wishListCount))]
      ),
      _vm._v(" "),
      _c(
        "svg",
        {
          attrs: {
            fill: "none",
            height: "40",
            viewBox: "0 0 24 24",
            width: "40",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "m12.62 20.8101c-.34.12-.9.12-1.24 0-2.9-.99-9.38-5.12-9.38-12.12 0-3.09 2.49-5.59 5.56-5.59 1.82 0 3.43.88 4.44 2.24 1.01-1.36 2.63-2.24 4.44-2.24 3.07 0 5.56 2.5 5.56 5.59 0 7-6.48 11.13-9.38 12.12z",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "1.5",
              opacity: ".4",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }