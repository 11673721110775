var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.$ceres.isSSR,
          expression: "!$ceres.isSSR",
        },
      ],
      staticClass: "cookie-bar",
      class: {
        out: !_vm.isVisible,
        "border-top bg-white": _vm.isVisible,
        "fixed-bottom": !_vm.isShopBuilder || false,
      },
    },
    [
      _vm.isVisible
        ? _c("div", { staticClass: "container-max" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isExpanded,
                    expression: "!isExpanded",
                  },
                ],
                staticClass: "row py-3",
                class: _vm.classes,
                style: _vm.styles,
              },
              [
                _c("div", { staticClass: "col-12 col-md-8" }, [
                  _c("p", { domProps: { innerHTML: _vm._s(_vm.text) } }),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm._l(_vm.consentGroups, function (consentGroup) {
                        return [
                          consentGroup.consents.length > 0
                            ? _c(
                                "span",
                                {
                                  key: consentGroup.key,
                                  staticClass:
                                    "custom-control custom-switch custom-control-appearance d-md-inline-block mr-3",
                                },
                                [
                                  _c("input", {
                                    staticClass: "custom-control-input",
                                    attrs: {
                                      type: "checkbox",
                                      id:
                                        _vm._cid + "-group-" + consentGroup.key,
                                      disabled:
                                        consentGroup.necessary ||
                                        _vm.necessaryOnly(consentGroup),
                                    },
                                    domProps: {
                                      checked:
                                        _vm.isConsented(consentGroup.key) ||
                                        consentGroup.necessary ||
                                        _vm.necessaryOnly(consentGroup),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.toggleConsent(
                                          consentGroup.key
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-control-label",
                                      attrs: {
                                        for:
                                          _vm._cid +
                                          "-group-" +
                                          consentGroup.key,
                                      },
                                    },
                                    [
                                      consentGroup.label.length > 0
                                        ? [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(consentGroup.label) +
                                                "\n                                "
                                            ),
                                          ]
                                        : [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$translate(
                                                    "Ceres::Template.privacySettingsDefaultGroup"
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ],
                                    ],
                                    2
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "text-primary text-appearance d-block d-md-inline-block",
                          attrs: {
                            href: "#",
                            "data-testing": "cookie-bar-show-more-information",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              _vm.isExpanded = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$translate(
                                "Ceres::Template.cookieBarMoreSettings"
                              )
                            )
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "button-order col-12 col-md-4 pt-3 pt-md-0" },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-block btn-default btn-appearance button-order-1 mb-2 mt-0",
                        attrs: { "data-testing": "cookie-bar-accept-all" },
                        on: {
                          click: function ($event) {
                            _vm.acceptAll()
                            _vm.close()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$translate(
                                "Ceres::Template.cookieBarAcceptAll"
                              )
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.showRejectAll
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-block btn-default btn-appearance button-order-2 mb-2 mt-0",
                            attrs: { "data-testing": "cookie-bar-deny-all" },
                            on: {
                              click: function ($event) {
                                _vm.denyAll()
                                _vm.close()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$translate(
                                    "Ceres::Template.cookieBarDenyAll"
                                  )
                                ) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-block btn-default button-order-3 mb-2 mt-0",
                        attrs: { "data-testing": "cookie-bar-save" },
                        on: {
                          click: function ($event) {
                            _vm.storeConsents()
                            _vm.close()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$translate("Ceres::Template.cookieBarSave")
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm.isExpanded
              ? _c(
                  "div",
                  {
                    staticClass: "row py-3",
                    class: _vm.classes,
                    style: _vm.styles,
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 mb-3" },
                      [
                        _c("privacy-settings", {
                          attrs: { "consent-groups": _vm.consentGroups },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-md-3" }, [
                      _c(
                        "a",
                        {
                          staticClass:
                            "text-primary text-appearance d-inline-block mb-3",
                          attrs: {
                            href: "#",
                            "data-testing": "cookie-bar-hide-more-information",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              _vm.isExpanded = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$translate("Ceres::Template.cookieBarBack")
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-md-9" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 col-md-4 mt-2 mt-md-0" },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-block btn-default btn-appearance",
                                attrs: {
                                  "data-testing":
                                    "cookie-bar-expanded-accept-all",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.acceptAll()
                                    _vm.close()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$translate(
                                        "Ceres::Template.cookieBarAcceptAll"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.showRejectAll
                          ? _c(
                              "div",
                              { staticClass: "col-12 col-md-4 mt-2 mt-md-0" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-block btn-default btn-appearance",
                                    attrs: {
                                      "data-testing":
                                        "cookie-bar-expanded-deny-all",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.denyAll()
                                        _vm.close()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$translate(
                                            "Ceres::Template.cookieBarDenyAll"
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-md-4" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-block btn-default",
                              attrs: {
                                "data-testing": "cookie-bar-expanded-save",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.storeConsents()
                                  _vm.close()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.$translate(
                                      "Ceres::Template.cookieBarSave"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ])
        : _c("div", [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-appearance",
                attrs: {
                  "aria-label": _vm.$translate(
                    "Ceres::Template.cookieBarPrivacySettings"
                  ),
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    _vm.isCollapsed = false
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-shield float-none" }),
                _vm._v(" "),
                _c("span", { staticClass: "d-none d-sm-inline-block" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$translate("Ceres::Template.cookieBarPrivacySettings")
                    )
                  ),
                ]),
              ]
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }