<template>
    <div :class="{'p-0' : $ceres.isShopBuilder}">
        <label :for="'item-search_'+_uid" class="sr-only">{{ $translate('PuresportsTheme::Template.headerSearchBarPlaceholder') }}</label>
        <div class="input-group input-group-lg">
            <input :id="'item-search_'+_uid" type="search" :placeholder="$translate('PuresportsTheme::Template.headerSearchBarPlaceholder')" class="form-control border-primary" ref="searchInput" v-model="searchString" @input="onValueChanged($event.target.value)"
                @keyup.enter="search()" @focus="isSearchFocused = true" @blur="onBlurSearchField($event)" :autofocus="isShopBuilder" :aria-label="$translate('Ceres::Template.headerSearchTerm')">
            <div class="input-group-append">
                <slot name="search-button">
                    <button class="btn btn-outline-primary py-0 px-3" type="submit" @click="search()" :aria-label="$translate('Ceres::Template.headerSearch')">
                        <span class="sr-only">{{ $translate('PuresportsTheme::Template.headerSearchButtonName') }}</span>
                        <svg fill="none" height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="m11.5 21c5.2467 0 9.5-4.2533 9.5-9.5 0-5.24671-4.2533-9.5-9.5-9.5-5.24671 0-9.5 4.25329-9.5 9.5 0 5.2467 4.25329 9.5 9.5 9.5z"/><path d="m22 22-2-2" opacity=".4"/></g></svg>
                    </button>
                </slot>
            </div>
        </div>

        <template v-if="isSearchFocused">
            <div v-show="hasInitialInput || $ceres.isShopBuilder">
                <slot name="autocomplete-suggestions">
                    <div class="autocomplete-suggestions shadow bg-white w-100 ">
                        <search-suggestion-item
                            :show-images="showItemImages"
                            suggestion-type="item">
                        </search-suggestion-item>
                    </div>
                </slot>
            </div>
        </template>
    </div>
</template>

<script>
import UrlService from "../../services/UrlService";
import { isNullOrUndefined, defaultValue } from "../../helper/utils";
import { pathnameEquals } from "../../helper/url";
import { mapState } from 'vuex';
import { debounce } from '../../helper/debounce';

export default {

    name: "item-search",

    props: {
        showItemImages:
        {
            type: Boolean
        },
        forwardToSingleItem:
        {
            type: Boolean,
            default: App.config.search.forwardToSingleItem
        },
        timeout:
        {
            type: Number,
            default: 200
        },
        searchMinLength:
        {
            type: Number,
            default: 2
        }
    },

    data()
    {
        return {
            isSearchFocused: App.isShopBuilder,
            onValueChanged: null,
            searchString: "",
            hasInitialInput: false
        };
    },

    computed:
    {
        hasAutocompleteResults()
        {
            const item       = this.autocompleteResult.item;
            const category   = this.autocompleteResult.category;
            const suggestion = this.autocompleteResult.suggestion;

            return App.isShopBuilder || (item && item.length) || (category && category.length) || (suggestion && suggestion.length);
        },

        isShopBuilder()
        {
            return App.isShopBuilder;
        },

        ...mapState({
            autocompleteResult: state => state.itemSearch.autocompleteResult,
            moduleSearchString: state => state.itemList.searchString,
            autocompleteIsLoading: state => state.itemSearch.autocompleteIsLoading
        })
    },

    mounted()
    {
        this.onValueChanged = debounce(searchString =>
        {
            this.autocomplete(searchString);
        }, defaultValue(this.timeout, 200));

        this.$nextTick(() =>
        {
            const urlParams = UrlService.getUrlParams(document.location.search);

            this.$store.commit("setItemListSearchString", urlParams.query);

            this.$refs.searchInput.value = !isNullOrUndefined(urlParams.query) ? urlParams.query : "";
        });
    },

    methods:
    {
        search()
        {
            if (this.$refs.searchInput.value.length)
            {
                if (pathnameEquals(App.urls.search))
                {
                    this.$store.dispatch("searchItems", this.$refs.searchInput.value);
                }
                else
                {
                    window.open(`${App.urls.search}?query=${ this.searchString }`, "_self", false);
                }
            }
        },

        autocomplete(searchString)
        {
            if (searchString.length >= this.searchMinLength)
            {
                this.$store.dispatch("loadItemSearchAutocomplete", searchString);
            }
            else
            {
                this.$store.commit("setAutocompleteResult", { item: [], category: [], suggestion: [] });

                // hide the autocomplete box
                this.hasInitialInput = false;
            }
        },

        // hide search, if targetElement of the blur event is not a child of components' root element
        onBlurSearchField(event)
        {
            const target = event.relatedTarget;

            if (isNullOrUndefined(target) || !isNullOrUndefined(target) && !this.$el.contains(target))
            {
                this.isSearchFocused = false;
            }
        }
    },

    watch:
    {
        // set the current search string, after clicking on a suggestion
        moduleSearchString(newVal)
        {
            if (newVal && newVal.length)
            {
                this.searchString = newVal;
            }
        },

        autocompleteIsLoading(newVal, oldVal) {
            // when client was loading and has stopped now, the autocomplete box should be shown
            if (oldVal && !newVal) {
                this.hasInitialInput = true;
            }
        }
    }
}
</script>
