var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading d-flex text-center" }, [
      _c("div", { staticClass: "loading-animation m-auto" }, [
        _c("div", { staticClass: "rect1 bg-appearance" }),
        _vm._v(" "),
        _c("div", { staticClass: "rect2 bg-appearance" }),
        _vm._v(" "),
        _c("div", { staticClass: "rect3 bg-appearance" }),
        _vm._v(" "),
        _c("div", { staticClass: "rect4 bg-appearance" }),
        _vm._v(" "),
        _c("div", { staticClass: "rect5 bg-appearance" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }