var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    class: _vm.pictureClass,
    attrs: {
      alt: _vm.alt,
      height: _vm.height,
      src: _vm.imageUrl,
      width: _vm.width,
      decoding: "async",
      loading: "lazy",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }